<template>
    <div class="title-box">
        <h4 :class="{'module-title': isModule, 'sub-module-title': !isModule}" v-if="title != ''">{{ title }}</h4>
        <h5 :class="{'module-sub-title': isModule, 'sub-module-sub-title': !isModule}" v-if="subTitle != ''">{{ subTitle }}</h5>
    </div>
</template>

<script>
    export default {
        name: 'module-title',
        props: {
            title: {
                type: String,
                default: ''
            },
            subTitle: {
                type: String,
                default: ''
            },
            isModule: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .title-box {
        .module-title {
            display: inline;
            padding: 6px 30px;
            color: #FFF;
            font-size: 14px;
            font-weight: bold;
            background-color: @theme-color;
        }
        .sub-module-title {
            color: @theme-color;
            font-weight: bold;
            font-size: 18px;
        }
        .module-sub-title {
            margin-top: 16px;
            font-weight: bold;
            font-size: 14px;
        }
        .sub-module-sub-title {
            margin-top: 4px;
            font-weight: bold;
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1023px) {
        .title-box {
            .module-title {
                padding: 0.16rem 0.2666rem;
            }
            .sub-module-title {
                color: @theme-color;
                font-weight: bold;
                font-size: 0.37333rem;
            }
            .module-sub-title {
                margin-top: 0.42666rem;
                font-size: 0.2666rem;
            }
            .sub-module-sub-title {
                margin-top: 0.10666rem;
                font-size: 0.2666rem;
            }
        }
    }
</style>