<template>
    <div class="programme">
        <header class="programme-header">
            <h1>信息化规划与集成方案设计</h1>
        </header>
        <div class="programme-content">
            <div class="one" v-if="data.one != void 0">
                <module-title :title="data.one.title" />
                <p class="one-content">{{ data.one.content }}</p>
                <ul class="one-list">
                    <li v-for="(item, i) in data.one.list" :key="i">
                        <div>
                            <h2 class="one-title">{{ item.title }}</h2>
                            <p class="one-list-content">{{ item.content }}</p>
                        </div>
                        <img :src="item.img" alt="">
                    </li>
                </ul>
            </div>
            <div class="two" v-if="data.two != void 0">
                <module-title :title="data.two.title" />
                <div class="two-content">
                    <p>{{ data.two.content }}</p>
                    <img :src="data.two.img" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 信息化规划与集成方案设计
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';

    // vuex
    import { mapMutations } from 'vuex';

    // API
    import { getProgramme } from '@/api/request';

    export default {
        name: 'programme',
        created () {
            this.getProgrammeAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getProgrammeAjax () {
                getProgramme().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                });
            }
        },
        components: {
            ModuleTitle
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .programme {
        .programme-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .programme-content {
            padding: 50px 40px;
            font-size: 14px;
            .one {
                margin-bottom: 100px;
                .one-content {
                    margin: 50px 0 30px;
                }
                .one-list {
                    display: flex;
                    align-items: flex-start;
                    li {
                        flex: 1;
                        position: relative;
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0;
                        }
                        div {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            .one-title {
                                padding-top: 40%;
                                height: 43%;
                                color: @theme-color;
                                text-align: center;
                                font-size: 16px;
                                box-shadow: border-box;
                            }
                            .one-list-content {
                                height: 56%;
                                padding: 20px;
                                color: #666;
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }
            .two {
                .two-content {
                    position: relative;
                    top: -20px;
                    display: flex;
                    align-items: center;
                    p {
                        margin-right: 20px;
                        line-height: 1.5;
                        color: #333;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .programme {
            .programme-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
            .programme-content {
                padding: 0.8rem 0.5333rem;
                font-size: 0.32rem;
                .one {
                    margin-bottom: 1.333rem;
                }
            }
        }
    }

    @media screen and (max-width: 768px) and (max-width: 1023px) {
        .programme {
            .programme-content {
                .one {
                    .one-list {
                        li {
                            width: 100%;
                            div {
                                .one-title {
                                    height: 40%;
                                }
                                .one-list-content {
                                    padding: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .programme {
            .programme-content {
                .one {
                    .one-list {
                        flex-direction: column;
                        li {
                            margin: 0 0 0.533rem 0;
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
                .two {
                    .two-content {
                        position: static;
                        margin-top: 0.533rem;
                        flex-direction: column;
                        p {
                            margin: 0 0 0.426rem 0;
                        }
                    }
                }
            }
        }
    }
</style>